.OrgListItem-img {
    height: auto;
    max-height: 200px;
    width: auto;
    max-width: 200px;
}

.OrgListItem-main-card {
    display: flex;
    border-radius: 20px;
    padding: 20 px;
    flex-direction: column;
    justify-content: center;
    min-height: 200 px;
    min-width: 300px;
    max-width: 300px;
    height: 400px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    padding: 14px 10px 10px 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.bannerImg {    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    height: auto !important;
    padding-bottom: 20px;
    width: 100%;
  
}

/* Container holding the image and the text */
.container {
    position: relative;
  }
  
  /* Bottom right text */
  .text-block {
    position: absolute;
    bottom: 20px;
    right: 10px;
    background-color: white;
    background-size: contain;
    color: black;
    padding-left: 30px;
    padding-right: 50px;
    width: 400px;
    max-width: 100%;
    height: auto;
  
    opacity: 0.9;
  }

  .imageHolder{
      min-height: 200px;      
      justify-content: center;
      
  }