.NFTListItem-img {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.NFTListItem-main-card {
  display: flex;
  border-radius: 20px;
   
  flex-direction: column;
  justify-content: center;
  min-height: 200 px;
  height: 400px;
  min-width: 300px;
  max-width: 300px;

  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  
  cursor: pointer;
  margin-bottom: 10px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.results {
  padding-top: 200 px;
  padding: 14px 10px 10px 16px;
  background: rgb(194, 224, 199);
  flex-direction: column;
  min-height: 200 px;
  justify-content: center;
}

.assetDetails {
  color: tomato;
}

.assetDetailsTxt {
  font-weight: bold;
  color: tomato;
}
.connectPublicBtn {
  margin-top: 50px;
}
.sectionpad {
  padding-top: 30px;
  padding-bottom: 30px;
}

.buttonDetails {
  justify-content: center;
}

.imageHolder {
  min-height: 200px;
  justify-content: center;
}

/* Bottom right text */
.text-block {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background-color: white;
  background-size: contain;
  color: black;
  padding-left: 30px;
  padding-right: 50px;
  width: 400px;
  max-width: 100%;
  height: auto;

  opacity: 0.9;
}

.bannerImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  height: auto !important;
  padding-bottom: 20px;
  width: 100%;
}

.modalDiv {
  width: 200px;
  height: 350px ;
}

.modalImage {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.donateButton{
  width: 100%;
}